@import 'src/style/base/mixin';
@import 'src/style/base/animations';

.preloader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  background-color: $mainBgColor;

  & .spinner {
    position: absolute;
    width: 50px;
    height: 50px;
    @include animation('preloader 1s linear infinite');
  }
}