@import './mixin';

@include keyframes(flareAnimation) {
  0% {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@include keyframes(preloader) {
  0% { transform: rotate(0); }
  100% { transform: rotate(360deg); }
}