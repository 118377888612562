@import './functions';

// colors
$whiteColor: #ffffff;
$blackColor: #000000;
$deepSeaGreen: #0d6056;
$genoa: #16786c;
$lightGenoa: #E3f1EE;
$beigeColor: #f2f2f2;

// base
$mainBgColor: $beigeColor;

//shadow
$shadowButton: 0 1px 4px 0 rgba(0, 0, 0, 0.15);

// font-size
$fontSize12: rem(12); // 12px
$fontSize14: rem(14); // 14px
$fontSize16: rem(16); // 16px
$fontSize18: rem(18); // 18px
$fontSize20: rem(20); // 20px
$fontSize22: rem(22); // 22px
$fontSize23: rem(23); // 23px
$fontSize28: rem(28); // 28px
$fontSize30: rem(30); // 30px
$fontSize32: rem(32); // 32px
$fontSize38: rem(38); // 38px
$fontSize40: rem(40); // 40px

$fwb: 600; // font weight bold

$fontFamily: 'Open Sans', sans-serif;